<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="维度2分类"
          @head-save="headSave"
          @head-save-back="headSave(true)"
          @head-cancel="headCancel"
        ></head-layout>
        <form-layout
          ref="formLayout"
          :column="formColumn"
          :dataForm.sync="dataForm"
        ></form-layout>
        <head-layout
          head-title="知识分类"
          :head-btn-options="headBtn"
          @head-add-tabs="headAddTabs"
          @head-remove-tabs="headRemoveTabs"
        />
        <grid-head-layout :search-columns="searchColumns"
                          @grid-head-search="searchChange" @grid-head-empty="searchReset"/>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @row-remove="rowRemove"
          @row-save="rowUpdate"
        ></grid-layout>
      </el-main>
    </el-container>
    <common-dialog
      v-if="showDialog"
      width="60%"
      dialogTitle="知识分类"
      :show-confirm-btn="formType != 'view'"
      @cancel="closeDialog"
      @confirm="handleNodeSave">
      <avue-form
        :option="dialogOption"
        ref="addFormList"
        v-model="formList"
        @submit="saveList"
      ></avue-form>
    </common-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {
  saveTree as saveForm,
  getCategoryHeadDetail,
  getPage,
  removeList,
  saveList
} from "@/api/knowledgeClassifcation/knowledge";
import CommonDialog from "@/components/CommonDialog";


export default {
  name: "eduexamEdit",
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonDialog,
  },
  data() {
    return {
      showDialog: false,
      formList: {},
      formType: '',
      // form: {
      //   status: 1,
      // },
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      type: '',
      roleBox: false,
      tableLoading: false,
      dialogVisible: false,
      knowledgeClassifyId: '',
      query: {},
      dataForm: {},
      saveLoading: false,
      handleSelectedValue: [], //操作后选中的值
      examuserData: [],
      selectData: [],
      prop: undefined,
      // type: undefined,
      selectionList: [],
      disabledErpItemList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      searchColumns: [
        {
          label: '',
          prop: "cateLineName",
          span: 4,
          placeholder: "请输入分类名称",
        },
        {
          label: '',
          prop: "cateLineCode",
          span: 4,
          placeholder: "请输入分类编码",
        },
      ],
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    ids() {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        if (ele.id) {
          ids.push(ele.id);
        }
      });
      return ids.join(",");
    },
    gridRowBtn() {
      let buttonBtn = [];
      // if (this.permissionList.editBtn) {
      // buttonBtn.push(
      //   {
      //     label: this.$t(`cip.cmn.btn.viewBtn`),
      //     emit: "row-view",
      //     type: "button",
      //     icon: ""
      //   });
      // // }
      if (this.type !== 'view') {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.saveBtn`),
            emit: "row-save",
            type: "button",
            icon: ""
          });
      }
      if (this.type !== 'view') {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-remove",
            type: "button",
            icon: ""
          })
      }
      return buttonBtn;
    },
    tableOptions() {
      return {
        menuWidth: 220,
        // index: true,
        // indexLabel: "序号",
        column: [
        {
            label: "分类编码",
            prop: "cateLineCode",
            align: "center",
            width: 280,
            overHidden: true,
            cell: true,
            rules: [
              {
                required: true,
                message: '请输入分类编码',
                trigger: 'blur'
              }
            ]
          },
          {
            label: "分类名称",
            prop: "cateLineName",
            align: "left",
            width: 280,
            overHidden: true,
            cell: true,
            rules: [
              {
                required: true,
                message: '请输入分类名称',
                trigger: 'blur'
              }
            ]
          },
          {
            label: "排序",
            prop: "sort",
            align: "left",
            width: 120,
            overHidden: true,
            cell: true
          },
          {
            label: "描述",
            prop: "remark",
            align: "left",
            overHidden: true,
            cell: true,
          },
        ]
      }
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduexam_add, true),
        viewBtn: this.vaildData(this.permission.eduexam_view, true),
        delBtn: this.vaildData(this.permission.eduexam_delete, true),
        editBtn: this.vaildData(this.permission.eduexam_edit, true)
      };
    },
    headBtn() {
      if (this.dataForm.id && (this.type !== 'view')) {
        let buttonBtn = [];
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            btnOptType: 'primary',
          });
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove-tabs",
            type: "button",
            btnOptType: 'remove',
          });
        return buttonBtn;
      }
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.type != 'view') {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
            loading: this.saveLoading,
          });
      }
      if (this.type != 'view') {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-save-back",
            type: "button",
            icon: "",
            btnOptType: 'saveBack',
            loading: this.saveLoading,
          });
      }
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      return buttonBtn;
    },
    formColumn() {
      return [
        {
          label: "维度编码",
          prop: "cateCode",
          labelWidth: 120,
          placeholder: "请输入维度编码",
          span:12,
          maxlength: 20,
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入维度编码",
            trigger: "blur"
          }]
        },
        {
          label: "维度名称",
          prop: "cateName",
          labelWidth: 120,
          placeholder: "请输入维度名称",
          maxlength: 20,
          showWordLimit: true,
          span:12,
          rules: [{
            required: true,
            message: "请输入维度名称",
            trigger: "blur"
          }]
        },
        // {
        //   label: "维度英文缩写",
        //   prop: "cateEn",
        //   labelWidth: 120,
        //   placeholder: "请输入维度英文缩写",
        //   rules: [{
        //     required: true,
        //     message: "请输入维度英文缩写",
        //     trigger: "blur"
        //   }]
        // },
        {
          label: "描述",
          prop: "remark",
          labelWidth: 120,
          placeholder: "请输入描述",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 500,
          showWordLimit: true,
        },
      ]
    },
    dialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类编号",
            prop: "cateLineCode",
            placeholder: "请输入分类编号",
            maxlength: 20,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: true,
                message: "请输入分类编号",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "分类名称",
            prop: "cateLineName",
            placeholder: "请输入分类名称",
            disabled: !['edit', 'add'].includes(this.formType),
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "排序",
            prop: "sort",
            type: 'number',
            controls:false,
            placeholder: "请输入排序",
            disabled: !['edit', 'add'].includes(this.formType),
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "描述",
            prop: "remark",
            type: "textarea",
            rows: 3,
            span: 24,
            placeholder: "请输入描述",
            maxlength: 200,
            showWordLimit: true,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: false,
                message: "请输入描述",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
        ],
      };
    },
  },
  methods: {
    rowView(row) {
      this.formType = 'view';
      this.formList = row;
      this.showDialog = true;
    },
    rowRemove(row) {
      if(row.id){
        this.$confirm("确定删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeList(row.id);
        })
        .then(() => {
          this.onLoad(this.page, {});
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      }else{
        this.tableData = this.tableData.filter(item => item.$index !== row.$index)
      }

    },
    rowEdit(row) {
      this.formType = 'edit';
      this.formList = row;
      this.showDialog = true;
    },
    handleNodeSave() {
      this.$refs.addFormList.submit();
    },
    closeDialog() {
      this.formList = {};
      this.$refs.addFormList.resetForm();
      this.showDialog = false
    },
    rowUpdate(form,index,done,loading){
      if(!form.cateLineName) {
        this.$message.warning('请输入分类名称');
        return
      }
      if(!form.cateLineCode) {
        this.$message.warning('请输入分类编码');
        return
      }
      // if(!form.cateLineCode) {
      //   this.$message.warning('请输入分类编码');
      //   return
      // }
      this.$loading();
      let params = {
        ...form,
        cateCode: this.dataForm.cateCode,
        cateEn: this.dataForm.cateEn,
        cateName: this.dataForm.cateName,
        cateId: this.dataForm.id,
      }
      saveList(params)
        .then((res) => {
          const {msg, data} = res.data;
          this.$message.success(msg);
          this.onLoad(this.page, {});
        }).finally(() => {
        this.$loading().close();
      })
        .catch((err) => {
          done();
        });
    },
    saveList(data, done) {
      this.$refs.addFormList.validate((valid) => {
        if (valid) {
          this.$loading();
          let params = {
            ...this.formList,
            cateCode: this.dataForm.cateCode,
            cateEn: this.dataForm.cateEn,
            cateName: this.dataForm.cateName,
            cateId: this.dataForm.id,
          }
          if (this.formList.id) {
            params.id = this.formList.id
          }
          saveList(params)
            .then((res) => {
              const {msg, data} = res.data;
              this.$message.success(msg);
              this.formList = {};
              this.$refs.addFormList.resetForm();
              this.onLoad(this.page, {});
              this.showDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    rpTaskAttributeSubmit(data) {
      this.formProcess.id = data.id;
      this.formProcess.paperName = this.dataForm.paperName;
      this.handleStartProcess5(true).then((res) => {
        data.processInstanceId = res.data.data;
        data.status = 3; // 设置状态为新增生成 审核中
        save(data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      });
    },
    headAddTabs() {
      this.formType = 'add';
      // this.showDialog = true;
      this.tableData.push({
        $cellEdit: true,
        id: '',
        sort: 0,
        cateLineName: '',
        cateLineCode: '',
        remark: ''
      })
    },
    headRemoveTabs() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      let addIds = []
      this.selectionList.forEach(item => {
        if(item.id == ''){
          addIds.push(item.$index)
        }
      })
      let deleteData = []
      if(addIds.length > 0){
        deleteData = this.tableData.filter(item =>  !addIds.includes(item.$index))
      }
      let addTable = deleteData.filter(item => item.id == '')
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeList(this.ids).then(() => {
            this.onLoad(this.page, {},addTable);
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {
        });
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page)
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params)
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page)
    },
    handleExamPaper() {
      if (this.type == "view") {
        this.dialogVisible = false;
      } else {
        this.dialogVisible = true;
      }
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    headSave(clo = false) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          if (this.knowledgeClassifyId) {
            this.$loading();
            let cateLineList = [];
            // for (let i = 0; i < this.handleSelectedValue.length; i++) {
            //   let user = {userId: this.handleSelectedValue[i]};
            //   examUserList.push(user);
            // }
            this.tableData.forEach(e => {
              let cc = {
                "cateLineName": e.cateLineName,
                "cateLineCode": e.cateLineCode,
                "remark": e.remark,
                "cateCode": this.dataForm.cateCode,
                "cateName": this.dataForm.cateName
              }
              cateLineList.push(cc)
            })
            let dataForm = this.$refs.formLayout.dataForm
            dataForm.cateLineList = cateLineList;
            dataForm.knowledgeClassifyId = this.knowledgeClassifyId;
            this.saveLoading = true
            saveForm(dataForm).then(
              (res) => {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.$refs.formLayout.dataForm = res.data.data;
                if (clo) {
                  this.headCancel();
                }
              },
              (error) => {
                window.console.log(error);
              }
            ).finally(() => {
              this.saveLoading = false;
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false
            });

          } else {
            this.$message.warning("保存失败，请关闭此页面后重新提交保存！")
          }
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$route.params.knowledgeClassifyId = this.knowledgeClassifyId;
      this.$router.back();
    },
    onLoad(page, params = {}, addList) {
      this.tableLoading = true;
      this.handleSelectedValue = [];
      if (!params.cateCode) {
        params.cateId = this.dataForm.id
      }
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records.forEach(item => {
          item.$cellEdit = true
        })
        this.tableData = data.records;
        if(addList) this.tableData = this.tableData.concat(addList)
        this.tableLoading = false;
        this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
      });
    },
    formData(id) {
      getCategoryHeadDetail(id).then((res) => {
        this.dataForm = res.data.data;
      });
    },
  },
  mounted() {
    this.type = this.$route.query.type
    if (this.type == "add") {
      this.dataForm.id = "";
      this.knowledgeClassifyId = this.$route.query.classifyId
    } else if (this.type == "edit") {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.cateName = row.cateName;
      this.dataForm.cateCode = row.cateCode;
      this.knowledgeClassifyId = row.knowledgeClassifyId
      this.dataForm.id = row.id
      this.formData(this.dataForm.id);
      this.onLoad(this.page);
    } else if (this.type == "view") {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.cateName = row.cateName;
      this.dataForm.cateCode = row.cateCode;
      this.knowledgeClassifyId = row.knowledgeClassifyId
      this.dataForm.id = row.id
      this.formData(this.dataForm.id);
      this.$refs.formLayout.$refs.form.allDisabled = true
      this.onLoad(this.page);
    }
  },
  created() {
  }
}
</script>

<style scoped lang="scss">

</style>
